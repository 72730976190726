

































































import { ObjectId } from 'bson';
import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';

import { useDbGetters, useAuthGetters, useUserGetters } from '@/store';
import Loading from '@/components/Loading.vue';
import Profile from '@/components/Profile.vue';

type ISponsorRequests = Array<{
  _id: ObjectId;
  id: string;
  program_id: ObjectId;
  programName: string;
  city: string;
  school: string;
  qualifications: Array<string>;
  ethnicityCulture: Array<string>;
  rewards: string;
}>;

const sponsorRequestHeaders = [
  { text: 'Program', value: 'programName', width: '20%' },
  { text: 'City', value: 'city', width: '15%' },
  { text: 'School', value: 'school', width: '15%' },
  { text: 'Culture', value: 'ethnicityCulture', width: '20%' },
  { text: 'Is unpaid ok?', value: 'rewards', width: '12%' },
  { text: 'Conditions', value: 'qualifications', width: '20%' }
];

export default defineComponent({
  name: 'ApproveSponsor',

  components: {
    Loading,
    Profile
  },

  setup() {
    const state = reactive<{
      sponsorRequests: ISponsorRequests;
      tokensAvailable: number;
    }>({
      sponsorRequests: [],
      tokensAvailable: 0
    });
    const searchText = ref('');
    const selectedRequests = ref([]);
    const sponsorLoading = ref(false);

    const { collection } = useDbGetters(['collection']);
    const { functions } = useDbGetters(['functions']);
    const userId = useUserGetters(['getId']).getId;

    const loadData = async () => {
      const resolveSponsorRequests = async () => {
        const sponsorRequestDocs = await collection.value!('SponsorRequest').find(
          {
            status: 'pending'
          },
          {
            projection: {
              _id: 1,
              program_id: 1,
              city: 1,
              school: 1,
              qualifications: 1,
              ethnicityCulture: 1,
              rewards: 1
            }
          }
        );
        const programIds = sponsorRequestDocs.map(sponsorRequest => sponsorRequest.program_id);
        const programDocs = await collection.value!('Program').find(
          {
            _id: { $in: programIds }
          },
          {
            projection: {
              _id: 1,
              programName: 1
            }
          }
        );
        state.sponsorRequests = sponsorRequestDocs.map(sponsorRequest => {
          const { programName } = programDocs.find(program =>
            program._id.equals(sponsorRequest.program_id)
          );
          return {
            ...sponsorRequest,
            id: sponsorRequest._id.toHexString(),
            programName
          };
        });
      };

      const resolveTokens = async () => {
        state.tokensAvailable =
          (await collection.value!('Tokens').count({
            newOwner_id: new ObjectId(userId.value)
          })) || 0;
      };

      await Promise.all([resolveSponsorRequests(), resolveTokens()]);
    };

    const sponsorBtnClick = async () => {
      if (selectedRequests.value.length === 0) {
        // eslint-disable-next-line no-alert
        alert('Please select a participant to sponsor'); // TODO: change this to a toast
        return;
      }
      sponsorLoading.value = true;
      const sponsorRequestId = selectedRequests.value[0].id;

      try {
        const result = await functions.value?.callFunction('approveSponsor', {
          sponsorRequestId,
          timeStamp: userId.value // Since this function is public, we want to keep the param name somewhat cryptic, should probably find another way to do this ASAP
        });
        if (result.statusCode === 200) {
          sponsorLoading.value = false;
          alert('Sponsor request approved'); // TODO: change this to a toast
          window.location.reload();
        } else {
          sponsorLoading.value = false;
          throw Error(result.error || 'sponsor error');
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        sponsorLoading.value = false;
        alert(error?.toString() ?? 'Error approving sponsor'); // TODO: change this to a toast
      }
    };

    return {
      ...toRefs(state),
      sponsorRequestHeaders,
      searchText,
      selectedRequests,
      sponsorBtnClick,
      sponsorLoading,
      loadData
    };
  }
});
